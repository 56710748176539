<template>
  <div>
    <v-navigation-drawer fixed v-model="getDrawer" class="drawer">
      <v-row class="align-center pa-0 ma-0">
        <span @click="clickLogo" class="cursor-pointer" style="color: #ffffff">
          <img :src="googleAdsLogo" height="40" width="132" alt="Google Ads Logo"/>
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="setDrawer(false)">
          <v-icon large color="#fff">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-list class="mt-5">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          class="pa-0 ma-0"
          @click="handleClick(item.url)"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="$t(item.text)"
              class="item-title"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="'mdi-chevron-right'" color="#fff"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-row class="pa-0 ma-0 justify-center">
        <v-btn class="register-btn button" height="48" @click="clickRegister">{{
          $t("unauthorized_header.register")
        }}</v-btn>
        <v-btn class="login-btn mt-2" @click="clickLogin">{{
          $t("unauthorized_header.login")
        }}</v-btn>
      </v-row>
      <v-footer class="footer">
        <v-col cols="12" class="ma-0">
          <span>Pivotad © {{ getCurrentYear }}</span>
        </v-col>
      </v-footer>
    </v-navigation-drawer>
    <Login
      :dialog="loginModal"
      @closeModal="loginModal = false"
      @openSignUp="registerModal = true"
    />
    <Registration
      :dialog="registerModal"
      @closeModal="registerModal = false"
      @openSignIn="loginModal = true"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { currentDate } from "@/utils";
import googleAdsLogo from "@/assets/icon/Pivotadd_logo.svg";

export default {
  name: "NavDrawerHeader",
  components: {
    Registration: () => import("@/components/MainLanding/Modals/Registration"),
    Login: () => import("@/components/MainLanding/Modals/Login"),
  },
  data: () => ({
    loginModal: false,
    registerModal: false,
    googleAdsLogo,
    items: [
      {
        text: "unauthorized_header.features",
        url: "/features",
      },
      {
        text: "unauthorized_header.services",
        url: "/services",
      },
      {
        text: "unauthorized_header.pricing",
        url: "/pricing",
      },
      {
        text: "unauthorized_header.company",
        url: "/company",
      },
      {
        text: "unauthorized_header.contacts",
        url: "/contact-us",
      },
      {
        text: "unauthorized_header.blog",
        url: "/blog",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getDrawer"]),
    getCurrentYear() {
      return currentDate;
    },
  },
  methods: {
    ...mapActions({ setDrawer: "setDrawer" }),
    clickLogo() {
      this.$router.push("/my-campaigns");
      this.setDrawer(false);
    },
    clickRegister() {
      this.registerModal = true;
      this.setDrawer(false);
    },
    clickLogin() {
      this.loginModal = true;
      this.setDrawer(false);
    },
    handleClick(url) {
      this.setDrawer(false);
      this.$router.push(url);
    },
  },
};
</script>
<style scoped lang="scss">
.drawer {
  padding: 20px 15px !important;
  width: 100% !important;
  background: linear-gradient(
      220deg,
      rgba(0, 64, 122, 0.85) 0.25%,
      rgba(9, 12, 29, 0.85) 100%
    ),
    url("../../assets/img/backimg.svg");
  background-position: top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  @media screen and (min-width: 450px) {
    width: 400px !important;
  }
}

.logo {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  font-style: normal;
  line-height: 35px;
}

.register-btn {
  text-transform: capitalize;
  width: 100%;
  margin-top: 80px;
  font-size: 18px !important;
}

.login-btn {
  box-shadow: none !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 18px !important;
}

.footer {
  margin-top: 50px;
  text-align: center;
  background-color: transparent !important;
  font-size: 18px;

  span {
    color: #a9b7cb !important;
  }

  .router-link {
    color: #ffffff;
    text-decoration: none;
  }
}

.item-title {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
</style>
